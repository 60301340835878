<template>
  <div :style="`font-family:${font},sans-serif`">
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">ทะเบียนจำหน่ายทรัพย์สิน</p>
          </div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1>จำหน่ายทรัพย์สิน</b-button>
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm_a" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns_a"
              :rows="rows_a"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm_a,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength_a,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-button variant="outline-warning" :to="{ name: 'inselling', params: { id: props.row } }">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                    </b-button>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength_a"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage_a(value))"
                    />
                    <span class="text-nowrap">of {{ total_a }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total_a"
                      :per-page="pageLength_a"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange_a($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>

      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">ประวัติทะเบียนจำหน่ายทรัพย์สิน</p>
          </div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <!-- <b-button v-b-modal.modal-1>จำหน่ายทรัพย์สิน</b-button> -->
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm_b" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel2">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns_b"
              :rows="rows_b"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm_b,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength_b,
              }"
              :line-numbers="true"
               :sort-options="{
              enabled: true,
              initialSortBy: { field: 'check_code', type: 'desc' },
            }"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-button variant="outline-warning" :to="{ name: 'sselling', params: { id: props.row } }">
                      <feather-icon icon="EyeIcon" class="mr-50" />
                    </b-button>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength_b"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage_b(value))"
                    />
                    <span class="text-nowrap">of {{ props.total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total_b"
                      :per-page="pageLength_b"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange_b($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
    </div>

    <!-- //model -->
    <div>
      <b-modal
        id="modal-1"
        cancel-variant="outline-secondary"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        title="จำหน่ายทรัพย์สิน"
        size="md"
        @ok="post_data"
        no-close-on-backdrop
      >
        <b-form>
          <div>
            <b-form-group label-cols="4" label-cols-lg="4" label="รหัส:">
              <template v-slot:label> รหัส <span class="text-danger"> *</span> </template>
              <b-form-input v-model="from.genDis" id="basicInput" type="number" placeholder="AUTO" disabled />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ปีงบประมาณ:">
              <template v-slot:label> ปีงบประมาณ <span class="text-danger"> *</span> </template>
              <b-form-input
                v-model="from.budget_year"
                id="basicInput"
                type="text"
                placeholder="ปีงบประมาณ"
                maxlength="4"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="รายละเอียด:">
              <template v-slot:label> รายละเอียด <span class="text-danger"> *</span> </template>
              <b-textarea id="basicInput" v-model="from.information" placeholder="รายละเอียด" />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ลงวันที่:">
              <template v-slot:label> ลงวันที่ <span class="text-danger"> *</span> </template>
               <v-date-picker
                id="example-datepicker3"
                label="ลงวันที่:"
                locale="th-TH"
               
                v-model="date1"
                @update:from-page="getHeaderInputDate"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <b-form-input
                    :value="formatDate(inputValue, 'date1')"
                    v-on="inputEvents"
                  />
                </template>
                <template #header-title>
                  {{ headerInputDate }}
                </template>
              </v-date-picker>
              <!-- <b-form-datepicker
                placeholder="ลงวันที่"
                v-model="from.action_date"
                id="example-datepicker"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="th"
              /> -->
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ผู้ขออนุญาต:">
              <template v-slot:label> ผู้ขออนุญาต <span class="text-danger"> *</span> </template>
              <v-select
                :options="alluser"
                label="firstname"
                v-model="from.requester_name"
                id="basicInput"
                type="text"
                placeholder="ผู้ขออนุญาต"
                @input="a_position(from.requester_name)"
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ตำเเหน่ง:">
              <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
              <v-select
                :options="department_Lists"
                label="dep_id"
                id="basicInput"
                v-model="from.requester_position"
                placeholder="ตำเเหน่ง"
                disabled
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ผู้อนุญาต:">
              <template v-slot:label> ผู้อนุญาต <span class="text-danger"> *</span> </template>
              <v-select
                :options="alluser"
                label="firstname"
                id="basicInput"
                v-model="from.approver_name"
                placeholder="ผู้อนุญาต"
                @input="b_position(from.approver_name)"
              />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ตำเเหน่ง:">
              <template v-slot:label> ตำเเหน่ง <span class="text-danger"> *</span> </template>
              <v-select :options="department_Lists" label="dep_id" id="basicInput" v-model="from.approver_position" placeholder="ตำเเหน่ง" disabled />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="เลือกกลุ่มงาน:">
              <template v-slot:label> เลือกกลุ่มงาน <span class="text-danger"> *</span> </template>
              <v-select v-model="selected" placeholder="เลือกกลุ่มงาน" label="work_gname" :options="options" />
            </b-form-group>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue';
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
import { BFormFile } from 'bootstrap-vue';
import { BFormSelect } from 'bootstrap-vue';
import { BFormDatepicker } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import { DatePicker } from "v-calendar";

export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
    DatePicker
  },

  data() {
    return {
        headerInputDate: "",
      date1: new Date(),
      from: {
        genDis: '',
        check_code: '',
        budget_year: '',
        information: '',
        action_date: '',
        requester_name: '',
        requester_position: '',
        approver_name: '',
        approver_position: '',
      },
      show: false,

      selected: null,
      options: [],
      columns_a: [
        {
          label: 'ลงวันที่',
          field: 'action_date',
        },
        {
          label: 'ปีงบประมาณ',
          field: 'budget_year',
        },
        {
          label: 'รหัส',
          field: 'check_code',
        },

        {
          label: 'รายละเอียด',
          field: 'information',
        },

        {
          label: 'สถานะ',
          field: 'status',
        },
        {
          label: 'จำหน่าย',
          field: 'action',
        },
      ],
      rows_a: [],
      total_a: '',
      pageLength_a: 50,
      currentPage_a: 1,

      columns_b: [
        {
          label: 'ลงวันที่',
          field: 'action_date_th',
        },
        {
          label: 'ปีงบประมาณ',
          field: 'budget_year',
        },
        {
          label: 'รหัส',
          field: 'check_code',
        },

        {
          label: 'รายละเอียด',
          field: 'information',
        },

        {
          label: 'สถานะ',
          field: 'status',
        },
        {
          label: 'รายการจำหน่าย',
          field: 'action',
        },
      ],
      rows_b: [],
      total_b: '',
      pageLength_b: 50,
      currentPage_b: 1,
      searchTerm_a: '',
      searchTerm_b: '',
      alluser: [],
      department_Lists: [],
      month: [
        '',
        'มกราคม',
        'กุมภาพันธ์ ',
        'มีนาคม ',
        'เมษายน ',
        'พฤษภาคม ',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ],
    };
  },
  mounted() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    this.get_data_table_a();
    this.get_data_table_b();
    this.DisposeOf();
    this.get_work_g();
    this.get_data_table();
    this.departmentLists();
  },
  methods: {
    async a_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.from.requester_position = res.data.message.data[0].dep_id;
    },
    async b_position(value) {
      // console.log(value.firstname);
      const { access_token } = await this.access_token();
      const url = `${API}registerWithdraw?fullname=${value.firstname}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.from.approver_position = res.data.message.data[0].dep_id;
    },
    async departmentLists() {
      const { access_token } = await this.access_token();
      const url = `${API}departmentLists`;
      const head = {
        headers: {
          authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.department_Lists = res.data.message.data;
      // console.log(res.data.message.data);
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=1&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const res_user = res.data.message.data.map((rs) => {
        return {
          ...rs,
          firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
        };
      });
      this.alluser = res_user;
    },
    exportExcel() {
      saveExcel({
        data: this.rows_a,
        fileName: 'ทะเบียนจำหน่ายทรัพย์สิน',
        columns: [
          {
            title: 'ทะเบียนจำหน่ายทรัพย์สิน',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'ลงวันที่',
                field: 'action_date_th',
              },
              {
                title: 'ปีงบประมาณ',
                field: 'budget_year',
              },
              {
                title: 'รหัส',
                field: 'check_code',
              },

              {
                title: 'รายละเอียด',
                field: 'information',
              },

              {
                title: 'สถานะ',
                field: 'status',
              },
              {
                title: 'จำหน่าย',
                field: 'action',
              },
            ],
          },
        ],
      });
    },
    exportExcel2() {
      saveExcel({
        data: this.rows_b,
        fileName: 'ประวัติทะเบียนจำหน่ายทรัพย์สิน',
        columns: [
          {
            title: 'ประวัติทะเบียนจำหน่ายทรัพย์สิน',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'ลงวันที่',
                field: 'action_date',
              },
              {
                title: 'ปีงบประมาณ',
                field: 'budget_year',
              },
              {
                title: 'รหัส',
                field: 'check_code',
              },

              {
                title: 'รายละเอียด',
                field: 'information',
              },

              {
                title: 'สถานะ',
                field: 'status',
              },
            ],
          },
        ],
      });
    },
    async post_data() {
      const { access_token } = await this.access_token();
      const url = `${API}disposeOf`;
      const data = {
        work_gid: this.selected.work_gid,
        code: this.from.genDis + '',
        check_code: this.from.budget_year + '-' + this.from.genDis,
        budget_year: this.from.budget_year,
        information: this.from.information,
        action_date: this.from.action_date,
        requester_name: this.from.requester_name.firstname,
        requester_position: this.from.requester_position.dep_id || this.from.requester_position,
        approver_name: this.from.approver_name.firstname,
        approver_position: this.from.approver_position.dep_id || this.from.approver_position,
      };
      // console.log(data);
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.post(url, data, header);
      // console.log(res.data.status);
      if (res.data.status === 200) {
        this.get_data_table_a();
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'บันทึก',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          title: 'ข้อมูลไม่ถูกต้อง',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async get_work_g() {
      const { access_token } = await this.access_token();
      const url = `${API}workgroups`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      // console.log(res.data.message.data);
      const res_amp = res.data.message.data.map((element) => {
        return {
          ...element,
          work_gname: `${element.work_gid} - ${element.work_gname}`,
        };
      });
      this.options = res_amp;
    },
    async DisposeOf() {
      const { access_token } = await this.access_token();
      const url = `${API}genDisposeOfAsset`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.from.genDis = res.data.message.data.code.toString().padStart(3, '0');
    },
    async get_data_table_a() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}disposeOf?_sort=-1`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      this.rows_a = res.data.message.data.map((element) => {
        return {
          ...element,
          action_date: `${parseInt(element.action_date.split('-')[2])} ${this.month[parseInt(element.action_date.split('-')[1])]} ${
            Number(element.action_date.split('-')[0]) + 543
          }`,
        };
      });
      this.total_a = res.data.message.total;
      this.show = false;
    },
    handlePagechange_a(event) {
      this.currentPage_a = event;
      this.get_data_table_a();
    },
    handleChangePage_a(event) {
      this.pageLength_a = event;
      this.get_data_table_a();
    },
    async get_data_table_b() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}disposeOfHistory`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res.data.message.data);
      this.rows_b = res.data.message.data.map((element) => {
        return {
          ...element,
          action_date_th: `${parseInt(element.action_date.split('-')[2])} ${this.month[parseInt(element.action_date.split('-')[1])]} ${
            Number(element.action_date.split('-')[0]) + 543
          }`,
        };
      });
      this.total_b = res.data.message.total;
      this.show = false;
    },
    handlePagechange_b(event) {
      this.currentPage_b = event;
      this.get_data_table_b();
    },
    handleChangePage_b(event) {
      this.pageLength_b = event;
      this.get_data_table_b();
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
       getHeaderInputDate(events) {
      switch (events.month) {
        case 1:
          this.headerInputDate = "มกราคม " + (events.year + 543);
          break;
        case 2:
          this.headerInputDate = "กุมภาพันธ์ " + (events.year + 543);
          break;
        case 3:
          this.headerInputDate = "มีนาคม " + (events.year + 543);
          break;
        case 4:
          this.headerInputDate = "เมษายน " + (events.year + 543);
          break;
        case 5:
          this.headerInputDate = "พฤษภาคม " + (events.year + 543);
          break;
        case 6:
          this.headerInputDate = "มิถุนายน " + (events.year + 543);
          break;
        case 7:
          this.headerInputDate = "กรกฎาคม " + (events.year + 543);
          break;
        case 8:
          this.headerInputDate = "สิงหาคม " + (events.year + 543);
          break;
        case 9:
          this.headerInputDate = "กันยายน " + (events.year + 543);
          break;
        case 10:
          this.headerInputDate = "ตุลาคม " + (events.year + 543);
          break;
        case 11:
          this.headerInputDate = "พฤศจิกายน " + (events.year + 543);
          break;
        case 12:
          this.headerInputDate = "ธันวาคม " + (events.year + 543);
          break;
        default:
          this.headerInputDate = "ข้อมูลผิดพลาด";
          break;
      }
      console.log(this.headerInputDate);
    },
    formatDate(date, val) {
      console.log(val);
      let formatDate = "";
      let dete_val = "";
      if (date != null) {
        const _y = date.split("/");
        const _date = parseInt(_y[2]) + 543;
        formatDate = `${_y[0]}/${_y[1]}/${_date}`;
        dete_val = _y[2] + "-" + _y[1] + "-" + _y[0];
        switch (val) {
          case "date1":
           this.from.action_date = dete_val;
            break;
          
        }
      } else {
        formatDate = "-";
      }
      if (formatDate.toString() == "/undefined/NaN") {
        formatDate = "";
      }
      return formatDate;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
